import React, { useEffect, useRef } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { BrowserRouter, Route, Routes } from 'react-router-dom';

import { PrivateRoute } from './PrivateRoute';
import { PublicRoute } from './PublicRoute';
import { AuthRoutes } from './AuthRoutes';
import { DataWarehouseRoutes } from './DataWarehouseRoutes';

import { login } from '../store/slices/authSlice';

import Main from "../components/Main";

import "antd/dist/antd.min.css";
import "../assets/styles/main.css";
import "../assets/styles/responsive.css";

export const AppRouter = () => {

    const dispatch = useDispatch();

    const token = useRef();

    const { token: tokenRedux } = useSelector(state => state.auth);

    const data = window.localStorage.getItem("data");

    useEffect(() => {
        
        if( data ){

            dispatch( login( JSON.parse( data ) ) );

        } 
        else{            
            localStorage.removeItem('data');
            localStorage.removeItem('lastPath');
        }      

    }, [ data, dispatch ]); 

    if( data ){
        const dataJSON = JSON.parse( data );

        if( dataJSON ){
            token.current = dataJSON.token;
        }
    }
    else{
        token.current = tokenRedux;
    }

    return (
        <BrowserRouter>  

            <Routes>
                
                <Route path="/auth/*" element={ <PublicRoute token={ token.current } children={ <AuthRoutes /> } /> } />   

                <Route path="/*" element={ <PrivateRoute token={ token.current } children={ <Main> <DataWarehouseRoutes /> </Main> } /> } />   

            </Routes>
        
        </BrowserRouter>
    )

}