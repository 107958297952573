import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import { Layout, Button, Row, Col, Typography, Form, Input, Image, Alert } from "antd";

import imageDWH from '../assets/images/data-warehouse.png';

import { Login } from "../actions/authActions";
import { useForm } from "../hooks/useForm";
import { authError } from "../store/slices/authSlice";

const { Title } = Typography;
const { Content } = Layout;

export const SignIn = () => {

    const dispatch = useDispatch();

    const { errorMessage } = useSelector( state => state.auth );

    const [loading, setLoading] = useState(false);

    const { correoElectronico, contrasena, onChange } = useForm({ correoElectronico: '', contrasena: '' });

    const [errores, setErrors] = useState({});

    const validateFiels = () => {

        let valid = true;
        let errores = {};

        const validEmail = /^[^@\s]+@[^@\s]+\.[^@\s]+$/.test(correoElectronico);

        if(!correoElectronico){    
            valid = false;
            errores.correoElectronico = 'Escribe el correo electrónico';
        }        
        else if(!validEmail){   
            valid = false;
            errores.correoElectronico = 'El correo electrónico no es valido';
        }
        
        if(!contrasena){
            valid = false;
            errores.contrasena = 'Escribe la contraseña';
        }

        setErrors(errores);

        return valid;
    }

    const handleLogin = () => {
        
        const valid = validateFiels();

        if( !valid ){
            return false;
        }

        setLoading( true );
        
        dispatch( Login({ correoElectronico, contrasena }, setLoading) )
        
    }

    useEffect(() => {
      
        if( errorMessage ){
            setErrors({ error: errorMessage });

            dispatch( authError( '' ) );
        }
        
    }, [ dispatch, errorMessage ])

    return (
        <>
            <Layout className="layout-default layout-signin" style={{ backgroundColor: '#fafafa' }}>

                <Content className="signin">

                    <Row gutter={[24, 0]} justify="center">

                        <Col xs={{ span: 24 }} lg={{ span: 6 }} md={{ span: 12 }} style={{ marginTop: '2em' }}>

                            <Title className="mb-15" style={{ textAlign: 'center', fontSize: '35px' }}>
                                Data Warehouse
                            </Title>

                            <div style={{ justifyItems: 'center', textAlign: 'center', marginBottom: '2em' }}>

                                <Image
                                    width={100}
                                    src={ imageDWH }
                                />

                            </div>

                            <Title className="font-regular text-muted" level={5}>
                                Inicia con tu correo electrónico y contraseña
                            </Title>

                            <Form
                                layout="vertical"
                                className="row-col"
                            >
                                <Form.Item
                                    className="username"
                                    label="Correo electrónico"
                                    name="email"
                                >
                                    <Input 
                                        placeholder="Ingrese el correo electrónico" 
                                        autoComplete="off" 
                                        name="correoElectronico"
                                        value={ correoElectronico }
                                        onChange={ ( e ) => onChange( e.target.value, 'correoElectronico' ) }
                                    />
                                </Form.Item>

                                {
                                    errores.correoElectronico
                                    &&
                                    <Form.Item>
                                        <Alert message={errores.correoElectronico} type='warning' showIcon />
                                    </Form.Item>
                                }

                                <Form.Item
                                    className="username"
                                    label="Contraseña"
                                    name="password"
                                >
                                    <Input 
                                        placeholder="Ingrese la contraseña" 
                                        autoComplete="off" 
                                        type="password"
                                        name="contrasena"
                                        value={ contrasena }
                                        onChange={ ( e ) => onChange( e.target.value, 'contrasena' ) }
                                    />

                                </Form.Item>

                                {
                                    errores.contrasena
                                    &&
                                    <Form.Item>
                                        <Alert message={errores.contrasena} type='warning' showIcon />
                                    </Form.Item>
                                }

                                <Form.Item>

                                    <Button                                        
                                        type="primary"
                                        style={{ width: "100%" }}
                                        onClick={ handleLogin }
                                        loading={ loading }
                                        htmlType="submit"
                                    >
                                        Ingresar
                                    </Button>

                                </Form.Item>

                                {
                                    errores.error
                                    &&
                                    <Form.Item>
                                        <Alert message={errores.error} type='error' showIcon />
                                    </Form.Item>
                                }

                            </Form>

                        </Col>

                    </Row>

                </Content>

            </Layout>
        </>
    );
    
}