import httpClientToken from "../services/httpClientToken";

export const ObtenerCausasAsignadas = ( params ) => {

    return new Promise( (resolve, eject) => {

        const data = JSON.parse(window.localStorage.getItem('data'));

        if( data ){
            const { token } = data;

            httpClientToken.post('/Api/CausaPenal/ObtenerCausasAsignadas/', params, token)
            .then( response => {
                if( response.status === 200 ){
                    resolve( response );
                }
            })
            .catch( (error) => {
                resolve( error.response );
            });
        }

    });

}