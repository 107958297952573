import { Spin, Typography } from "antd";

import eChart from "./configs/eChart";

import ReactApexChart from "react-apexcharts";

const { Title } = Typography;

function EChart({ graficaExpedientesDistrito, loading }) {

    let arrayCategorias = [];
    let arrayData = [];

    graficaExpedientesDistrito?.forEach( ( elem ) => {

        if( elem?.distritoJudicial ){
            arrayCategorias.push( elem.distritoJudicial ); 
            arrayData.push( elem.count ) 
        }
        
    });

    eChart.series[0].data = arrayData;
    eChart.options.xaxis.categories = arrayCategorias;

    return (
        <>
            <div style={{ paddingBottom: '1em' }}>
                <Title level={5}>Expedientes por distrito judicial</Title>
            </div>
          
            <div id="chart">

                {
                    loading
                    ?
                        <div style={{
                            margin: '20px 0',
                            marginBottom:' 20px',
                            padding: '30px 50px',
                            textAlign: 'center',
                            borderRadius: '4px',
                        }}>
                            <Spin size='large' />
                        </div>
                    :
                    <ReactApexChart
                        className="bar-chart"
                        options={ eChart.options }
                        series={ eChart.series }
                        type="bar"
                    />
                }

            </div>
            
        </>
    );
}

export default EChart;
