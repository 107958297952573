import React from 'react';

import { Navigate, Route, Routes } from 'react-router-dom';

import { SignIn } from "../pages/SignIn";

export const AuthRoutes = () => {

    return (
        <Routes> 

            <Route path="/sign-in/" exact element={ <SignIn /> } />                                  

            <Route path='/*' element={ <Navigate to="/auth/sign-in/" /> } />                          

        </Routes>
    )

}