
import { createSlice } from '@reduxjs/toolkit'

export const counterSlice = createSlice({
    name: 'auth',
    initialState: {
        status: 'not-authenticated', 
        token: null,
        nombre: null,
        errorMessage: null,
    },
    reducers: {
        login: ( state, { payload } ) => {
            state.status = 'authenticated';
            state.token = payload.token;
            state.nombre = payload.nombre;
            state.errorMessage = null;        
        },
        logout: ( state ) => {            
            state.status = 'not-authenticated';
            state.token = null;
            state.nombre = null;
            state.errorMessage = null;
        },
        authError: ( state, { payload } ) => {  
            state.status= 'error';
            state.errorMessage = payload;
        },
    },
})

export const { login, logout, authError } = counterSlice.actions

export default counterSlice.reducer