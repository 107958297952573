
import { Menu } from "antd";

import { BarsOutlined, DashboardFilled, KeyOutlined, UserOutlined } from "@ant-design/icons";

import { NavLink } from "react-router-dom";

import imageDWH from '../assets/images/data-warehouse.png';

function Sidenav() {

    return (
        <>
            <div className="brand">
                <img src={imageDWH} alt="" />
                <span>Data Warehouse</span>
            </div>

            <hr />
        
            <Menu theme="light" mode="inline">

                <Menu.Item key="1">

                    <NavLink to="/dashboard">
                        <span className="icon">
                            <DashboardFilled style={{ fontSize: '20px' }} />
                        </span>
                        <span className="label">Dashboard</span>
                    </NavLink>
                    
                </Menu.Item>

                <Menu.Item key="2">

                    <NavLink to="/Usuarios">
                        <span className="icon">
                            <UserOutlined style={{ fontSize: '20px' }} /> 
                        </span>
                        <span className="label">Usuarios</span>
                    </NavLink>

                </Menu.Item>

                <Menu.Item key="3">

                    <NavLink to="/ExpedienteVirtual">
                        <span className="icon">
                            <BarsOutlined style={{ fontSize: '20px' }} /> 
                        </span>
                        <span className="label">Expediente <br /> Virtual</span>
                    </NavLink>

                </Menu.Item>
                
                <Menu.Item key="4">

                    <NavLink to="/ConsultarExpedientes">
                        <span className="icon">
                            <BarsOutlined style={{ fontSize: '20px' }} /> 
                        </span>
                        <span className="label">Consultar <br /> Expedientes</span>
                    </NavLink>

                </Menu.Item>

                {/*<Menu.Item key="5">

                    <NavLink to="/Notificaciones">
                        <span className="icon">
                            <SendOutlined style={{ fontSize: '20px' }} /> 
                        </span>
                        <span className="label">Notificaciones</span>
                    </NavLink>

                </Menu.Item>*/}

                <Menu.Item key="6">

                    <NavLink to="/Apikeys">
                        <span className="icon">
                            <KeyOutlined style={{ fontSize: '20px' }} /> 
                        </span>
                        <span className="label">Api key´s</span>
                    </NavLink>

                </Menu.Item>

            </Menu>

        </>
    );
    
}

export default Sidenav;
