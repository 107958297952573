export const columnsNotificaciones = [
    {
        title: "Folio",
        dataIndex: "folio",
        key: "folio",
        width: 10,
    },
    {
        title: "Número de expediente",
        dataIndex: "expediente",
        key: "expediente",
        width: 10,
    },
    {
        title: "Juzgado",
        dataIndex: "juzgado",
        key: "juzgado",
        width: 20,
    },
    {
        title: "Actor",
        dataIndex: "actor",
        key: "actor",
        width: 15,
    },
    {
        title: "Demandado",
        dataIndex: "demandado",
        key: "demandado",
        width: 15,
    },
    {
        title: "Número de documentos",
        dataIndex: "numeroDocumentos",
        key: "numeroDocumentos",
        width: 3,
    },
];
