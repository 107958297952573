import React, { useEffect, useState } from "react";

import { Row, Modal, Col, Card, Table, Button, Typography, List, Form, Input, Alert, Tag, Switch } from "antd";

import { DeleteFilled, EditFilled, PlusOutlined } from "@ant-design/icons";

import { columnsApikeys } from "../helpers/columnsApikeys";

import { ActualizarApiKey, AgregarApiKey, ObtenerApiKeys } from "../actions/apiKeysActions";

const { Title } = Typography;

export const Apikeys = () => {

    const [isModalVisible, setIsModalVisible] = useState(false);

    const [rows, setRows] = useState( [] );

    const [loading, setLoading] = useState( true );
    const [loadingSave, setLoadingSave] = useState( false );

    const [descripcion, setDescripcion] = useState( '' );
    const [dominio, setDominio] = useState( '' );
    const [dominios, setDominios] = useState( [] );
    const [activo, setActivo] = useState( false );

    const [errors, setErrors] = useState( {} );
    const [referencia, setReferencia] = useState( '' );

    const handleClose = () => {

        setIsModalVisible( false );
        setReferencia( '' );
        setDescripcion( '' );
        setDominio( '' );
        setDominios( [] );
        setActivo( true );
        setErrors( {} );

    }

    const handleEditApiKey = ( id, desc, domains, activo ) => {

        setIsModalVisible( true );
        setReferencia( id );
        setDescripcion( desc );
        setDominios( domains );
        setActivo( activo );
        
    }

    const handleDeleteDomain = ( item ) => {

        const newDomains = dominios.filter( ( elem ) => elem !== item );
        setDominios( newDomains );

    }

    const handleAgregarDominio = () => {

        setErrors( {} );

        const validateDominio =  dominios.find( ( element ) => { return element === dominio });           
        
        if( dominio.includes('http') || dominio.includes('https') || dominio.includes(':') || dominio.includes('/') ){
            let errores = {};
    
            errores.error = 'El dominio no debe incluir http o https y ningun caracter como : /';
        
            setErrors( errores );  
        }
        else if( !validateDominio && dominio !== '' ){

            setDominios([
                ...dominios,
                dominio
            ]);

            setDominio( '' );
        }
        else{
            let errores = {};
    
            errores.error = dominio === '' ? 'Escribe un dominio' :'Ya se tiene agregado un dominio con la misma descripción';
        
            setErrors( errores );  
        }

    }

    const handleGuardarApiKey = async () => {

        setErrors({ });

        if( descripcion === '' ){
            setErrors({ error: 'Debes escribir el nombre de la aplicación' }); 
            return false;
        }
        else if( dominios.length === 0 ){
            setErrors({ error: 'Agrega un dominio por lo menos' }); 
            return false;
        }

        setLoadingSave( true );

        if( referencia){

            await ActualizarApiKey({ id: referencia, descripcion, dominios, activo }).then( response => {

                if( response ){

                    if( response.status === 200 ){          
                        
                        const { success, data } = response.data;

                        if( success ){

                            setTimeout(() => {

                                setRows(rows.map( ( elem ) => {
                                    if( elem.key === referencia){
                                        elem = {
                                            key: data.id,
                                            nombre: <Title level={5}>{ data.descripcion }</Title>,
                                            apikey: <Title level={5}>{ data.apiKey }</Title>,
                                            dominios: <List size="small" bordered={false} dataSource={ data?.dominios?.map( (e) => { return e.dominio; } )} renderItem={item => <List.Item>{item}</List.Item>} />,
                                            edit: <Button onClick={ () => handleEditApiKey( data.id, data.descripcion, data?.dominios?.map( (e) => { return e.dominio; } ), data.activo ) } icon={ <EditFilled />}>Editar</Button>,
                                            actions: <Tag color={ data.activo ? "#108ee9" : "#f50" }>{ data.activo ? 'Activa' : 'Desactivada' }</Tag>,
                                        };
                                    }
                                    return elem;
                                }));
    
                                handleClose();

                                setLoadingSave( false );
                                
                            }, 700);

                        }
                
                    }
                }
    
            })    

        }
        else{

            await AgregarApiKey({ descripcion, dominios }).then( response => {

                if( response ){

                    if( response.status === 200 ){          
                        
                        const { success, data } = response.data;

                        if( success ){

                            setTimeout(() => {

                                setRows([
                                    ...rows,
                                    {
                                        key: data.id,
                                        nombre: <Title level={5}>{ data.descripcion }</Title>,
                                        apikey: <Title level={5}>{ data.apiKey }</Title>,
                                        dominios: <List size="small" bordered={false} dataSource={ data?.dominios?.map( (e) => { return e.dominio; } )} renderItem={item => <List.Item>{item}</List.Item>} />,
                                        edit: <Button onClick={ () => handleEditApiKey( data.id, data.descripcion, data?.dominios?.map( (e) => { return e.dominio; } ), data.activo ) } icon={ <EditFilled />}>Editar</Button>,
                                        actions: <Tag color={ data.activo ? "#108ee9" : "#f50" }>{ data.activo ? 'Activa' : 'Desactivada' }</Tag>,
                                    }
                                ]);
    
                                handleClose();
                                
                                setLoadingSave( false );
                                
                            }, 700);

                        }
                
                    }
                }
    
            })    

        }

    }

    useEffect(() => {
      
        async function Obtener(){
            
            setRows( [ ] );    
            setLoading( true );            

            await ObtenerApiKeys().then( response => {

                if( response ){

                    if( response.status === 200 ){                       

                        setTimeout(() => {
                            
                            var data = response?.data?.map( ( elem ) => {
                                return {
                                    key: elem.id,
                                    nombre: <Title level={5}>{ elem.descripcion }</Title>,
                                    apikey: <Title level={5}>{ elem.apiKey }</Title>,
                                    dominios: <List size="small" bordered={false} dataSource={ elem?.dominios?.map( (e) => { return e.dominio; } )} renderItem={item => <List.Item>{item}</List.Item>} />,
                                    edit: <Button onClick={ () => handleEditApiKey( elem.id, elem.descripcion, elem?.dominios?.map( (e) => { return e.dominio; } ), elem.activo ) } icon={ <EditFilled />}>Editar</Button>,
                                    actions: <Tag color={ elem.activo ? "#108ee9" : "#f50" }>{ elem.activo ? 'Activa' : 'Desactivada' }</Tag>,
                                };
                            });
                            
                            setRows( data ); 
                            setLoading( false );
                
                        }, 700);
                        
                    }
                    else{
                        setLoading( false );
                    }
                }
                else{
                    setLoading( false );
                }

            })

        }

        Obtener();

    }, [ ])
  
    return (
        <>
            <div className="tabled">

                <Row gutter={[24, 0]}>

                    <Col xs="24" xl={24} >

                        <Card
                            bordered={false}
                            className="criclebox tablespace mb-24"
                            style={{ width: '100%' }}
                            extra={ <Button icon={<PlusOutlined />} onClick={ () => setIsModalVisible(true) }>Generar api key</Button> }
                        >
                            <div className="table-responsive" style={{marginTop: '20px'}}>

                                <Table
                                    className="ant-border-space"
                                    columns={ columnsApikeys }
                                    dataSource={ rows }
                                    pagination={ false }
                                    loading={ loading }
                                />
                            
                            </div>

                        </Card>

                    </Col>

                </Row>

            </div>

            <Modal 
                title={ referencia ? 'Modificar api key' : 'Generar api key' } 
                open={ isModalVisible } 
                centered 
                footer={[
                    <Button key="agregar" loading={ loadingSave } icon={<PlusOutlined />} onClick={ handleGuardarApiKey }>
                        Guardar
                    </Button>
                ]} 
                onCancel={ handleClose }
            >   

                <Form>
                    <Title style={{ fontSize: 12 }}>Nombre de la aplicación</Title>

                    <Form.Item>
                        <Input placeholder="Aplicación" value={descripcion} onChange={ (e) => setDescripcion(e.target.value) } />
                    </Form.Item>

                    <Form.Item>
                        <Input.Group compact>
                            <Input 
                                style={{ width: 'calc(100% - 85px)' }} 
                                placeholder="Agregar dominio"
                                value={ dominio }
                                onChange={ (e) => setDominio(e.target.value) }
                            />
                            <Button style={{ fontSize: '14px' }} onClick={ handleAgregarDominio }>
                                Agregar
                            </Button>
                        </Input.Group>
                    </Form.Item>

                    <Title style={{ fontSize: 12 }}>Permite a los sitios web especificados hacer solicitudes mediante el uso de la clave.</Title>

                    <Form.Item name="dominios">
                        <List 
                            size="small" 
                            header="Dominios"
                            dataSource={ dominios } 
                            renderItem={item => <List.Item actions={[ <Button onClick={ () => handleDeleteDomain( item ) } type="link" icon={<DeleteFilled />}> Eliminar </Button> ]}>{item}</List.Item>} 
                        />
                    </Form.Item>

                    <Form.Item label="Activar aplicación">
                        <Switch checked={ activo } onChange={ ( value ) => setActivo( value ) } />
                    </Form.Item>

                    {
                        errors.error
                        &&
                        <Form.Item>
                            <Alert message={ errors.error } type="warning" />
                        </Form.Item>
                    }

                </Form>
                 
            </Modal>
        </>
    );
    
}
