import React, { useEffect, useState } from "react";

import { Row, Modal, Col, Card, Table, Button, Typography, Pagination, Input } from "antd";

import { ClearOutlined, SearchOutlined } from "@ant-design/icons";

import { columnsUsuario } from "../helpers/columnsUsuarios";
import { columnsExpediente } from "../helpers/columnsExpediente";

import { ObtenerUsuarios, ObtenerUsuariosFiltro } from "../actions/usuarioActions";
import { ObtenerExpedientesAsignados } from "../actions/expedienteActions";
import { ObtenerCausasAsignadas } from "../actions/causaPenalActions";
import { columnsCausaPenal } from "../helpers/columnsCausaPenal";

const { Title } = Typography;

export const Usuarios = () => {

    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isModalVisibleCausaPenal, setIsModalVisibleCausaPenal] = useState(false);

    const [page, setPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [recordsCount, setRecordsCount] = useState(0);
    const [data, setData] = useState( [] );
    const [loading, setLoading] = useState( false );

    const [filtroAplicado, setFiltroAplicado] = useState( false );
    const [usuario, setUsuario] = useState( '' );

    const [referencia, setReferencia] = useState( '' );

    const [dataExpediente, setDataExpediente] = useState( [] );
    const [loadingExpediente, setLoadingExpediente] = useState( false );
    const [pageExpediente, setPageExpediente] = useState(1);
    const [rowsPerPageExpediente, setRowsPerPageExpediente] = useState(5);
    const [recordsCountExpediente, setRecordsCountExpediente] = useState(0);

    const [dataCausaPenal, setDataCausaPenal] = useState( [] );
    const [loadingCausaPenal, setLoadingCausaPenal] = useState( false );
    const [pageCausaPenal, setPageCausaPenal] = useState(1);
    const [rowsPerPageCausaPenal, setRowsPerPageCausaPenal] = useState(5);
    const [recordsCountCausaPenal, setRecordsCountCausaPenal] = useState(0);

    const handleLimpiarFiltro = () => {

        setUsuario( '' );
        setFiltroAplicado( false );
        setPage( 1 );
        setRecordsCount( 0 );
    }

    const handleBuscarUsuario = () => {

        setFiltroAplicado( true );
        setPage( 1 );
    }

    const handleDetalleExpediente = async ( id ) => {

        setIsModalVisible( true );
        setReferencia( id );
    }

    const handleDetalleCausaPenal = async ( id ) => {

        setIsModalVisibleCausaPenal( true );
        setReferencia( id );
    }
 
    useEffect(() => {
      
        async function Obtener(){

            setLoading( true );
            setData( [ ] );

            await ObtenerUsuarios( { page: page - 1, rowsPerPage } ).then( response => {
                
                if( response ){

                    if( response.status === 200 ){

                        if( response.data ){

                            setTimeout(() => {
                                
                                setRecordsCount( response.data.recordsCount ?? 0 );

                                var data = response?.data?.records.map( ( elem ) => {
                                    return {
                                        key: elem.id,
                                        nombre: <Title level={5}>{ elem.nombre }</Title>,
                                        apellido1: <Title level={5}>{ elem.apellido1 }</Title>,
                                        apellido2: <Title level={5}>{ elem.apellido2 }</Title>,
                                        correoElectronico: <Title level={5}>{ elem.correoElectronico }</Title>,
                                        telefonoCelular: <Title level={5}>{ elem.telefonoCelular }</Title>,
                                        numeroExpedientes: (
                                            <Title level={5} style={{ textAlign: 'center' }}>
                                                <Button disabled={ elem.numeroExpedientes === 0 } onClick={ () => handleDetalleExpediente( elem.id ) } type="default">{ elem.numeroExpedientes }</Button>
                                            </Title>
                                        ),
                                        numeroCausas: (
                                            <Title level={5} style={{ textAlign: 'center' }}>
                                                <Button disabled={ elem.numeroCausas === 0 } onClick={ () => handleDetalleCausaPenal( elem.id ) } type="default">{ elem.numeroCausas }</Button>
                                            </Title>
                                        ),
                                    };
                                });
                                
                                setData( data );
                                setLoading( false );

                            }, 1000);                            

                        }

                    }

                }

            });
        }

        if( !filtroAplicado ){
            Obtener();
        }

    }, [ page, rowsPerPage, filtroAplicado])    

    useEffect(() => {
      
        async function Obtener(){

            setLoading( true );
            setData( [ ] );

            await ObtenerUsuariosFiltro( { page: page - 1, rowsPerPage, usuario } ).then( response => {
                
                if( response ){

                    if( response.status === 200 ){

                        if( response.data ){

                            setTimeout(() => {
                                
                                setRecordsCount( response.data.recordsCount ?? 0 );

                                var data = response?.data?.records.map( ( elem ) => {
                                    return {
                                        key: elem.id,
                                        nombre: <Title level={5}>{ elem.nombre }</Title>,
                                        apellido1: <Title level={5}>{ elem.apellido1 }</Title>,
                                        apellido2: <Title level={5}>{ elem.apellido2 }</Title>,
                                        correoElectronico: <Title level={5}>{ elem.correoElectronico }</Title>,
                                        telefonoCelular: <Title level={5}>{ elem.telefonoCelular }</Title>,
                                        numeroExpedientes: (
                                            <Title level={5} style={{ textAlign: 'center' }}>
                                                <Button disabled={ elem.numeroExpedientes === 0 } onClick={ () => handleDetalleExpediente( elem.id ) } type="default">{ elem.numeroExpedientes }</Button>
                                            </Title>
                                        ),
                                        numeroCausas: (
                                            <Title level={5} style={{ textAlign: 'center' }}>
                                                <Button disabled={ elem.numeroCausas === 0 } onClick={ () => handleDetalleCausaPenal( elem.id ) } type="default">{ elem.numeroCausas }</Button>
                                            </Title>
                                        ),
                                    };
                                });
                                
                                setData( data );
                                setLoading( false );

                            }, 1000);                            

                        }

                    }

                }

            });
        }

        if( filtroAplicado ){
            Obtener();
        }

    }, [ page, rowsPerPage, usuario, filtroAplicado])    
  
    useEffect(() => {
      
        async function Obtener(){

            setLoadingExpediente( true );
            setDataExpediente( [ ] );

            await ObtenerExpedientesAsignados( { page: pageExpediente - 1, rowsPerPage: rowsPerPageExpediente, id: referencia } ).then( response => {
                
                if( response ){

                    if( response.status === 200 ){

                        if( response.data ){

                            setTimeout(() => {
                                
                                setRecordsCountExpediente( response.data.recordsCount ?? 0 );

                                var data = response?.data?.records.map( ( elem, index ) => {
                                    return {
                                        key: index,
                                        expediente: <Title level={5}>{ elem.numeroExpediente }</Title>,
                                        juzgado: <Title level={5}>{ 'Juzgado ' + elem.tipoJuzgado + ' ' + elem.materia + ' de ' + elem.distritoJudicial  }</Title>,
                                        actor: <Title level={5}>{ elem.actor }</Title>,
                                        demandado: <Title level={5}>{ elem.demandado }</Title>,
                                    };
                                });
                                
                                setDataExpediente( data );
                                setLoadingExpediente( false );

                            }, 1000);                            

                        }

                    }

                }

            });
        }

        if( isModalVisible ){
            Obtener();
        }

    }, [ pageExpediente, rowsPerPageExpediente, referencia, isModalVisible])    

    useEffect(() => {
      
        async function Obtener(){

            setLoadingCausaPenal( true );
            setDataCausaPenal( [ ] );

            await ObtenerCausasAsignadas( { page: pageCausaPenal - 1, rowsPerPage: rowsPerPageCausaPenal, id: referencia } ).then( response => {
                
                if( response ){

                    if( response.status === 200 ){

                        if( response.data ){

                            setTimeout(() => {
                                
                                setRecordsCountCausaPenal( response.data.recordsCount ?? 0 );

                                var data = response?.data?.records.map( ( elem, index ) => {
                                    return {
                                        key: index,
                                        nic: <Title level={5}>{ elem.nic }</Title>,
                                        nuc: <Title level={5}>{ elem.nuc }</Title>,
                                        juzgado: <Title level={5}>{ 'Juzgado ' + elem.tipoJuzgado + ' ' + elem.materia + ' de ' + elem.distritoJudicial  }</Title>,
                                        imputado: <Title level={5}>{ elem.imputado }</Title>,
                                        victima_ofendido: <Title level={5}>{ elem.victima_Ofendido }</Title>,
                                    };
                                });
                                
                                setDataCausaPenal( data );
                                setLoadingCausaPenal( false );

                            }, 1000);                            

                        }

                    }

                }

            });
        }

        if( isModalVisibleCausaPenal ){
            Obtener();
        }

    }, [ pageCausaPenal, rowsPerPageCausaPenal, referencia, isModalVisibleCausaPenal])    

    return (
        <>
            <div className="tabled">

                <Row gutter={[24, 0]}>

                    <Col xs="24" xl={24} >

                        <Card
                            bordered={false}
                            className="criclebox tablespace mb-24"
                            style={{ width: '100%' }}
                            extra={ 
                                <Input.Group compact style={{ width: '420px' }}>
                                    <Input 
                                        style={{ width: 'calc(100% - 110px)', borderBottomLeftRadius: 10, borderTopLeftRadius: 10 }} 
                                        placeholder="Buscar usuario"
                                        value={ usuario } 
                                        onChange={ (e) => { setUsuario( e.target.value ) } }
                                        disabled={ filtroAplicado }
                                    />
                                    <Button 
                                        style={{ fontSize: '14px' }} 
                                        icon={ filtroAplicado ? <ClearOutlined /> : <SearchOutlined />}
                                        onClick={ () => filtroAplicado ? handleLimpiarFiltro() : handleBuscarUsuario() }
                                        disabled={ usuario.length < 4 }
                                    >
                                        { filtroAplicado ? 'Limpiar' : 'Buscar' }
                                    </Button>
                                </Input.Group>
                            }
                        >
                            <div className="table-responsive" style={{marginTop: '20px'}}>

                                <Table
                                    className="ant-border-space"
                                    columns={ columnsUsuario }
                                    dataSource={ data }
                                    pagination={ false }
                                    loading={ loading }
                                />
                            
                            </div>

                            <div style={{ float: 'left', padding: '15px' }} >
                                <Title level={5}>Total de usuarios: { recordsCount }</Title>
                            </div>

                            <div style={{ float: 'right', padding: '15px' }} >
                                <Pagination 
                                    current={ page }
                                    pageSize={ rowsPerPage }
                                    total={ recordsCount } 
                                    onChange={ ( page, pageSize ) => { setPage( page ); setRowsPerPage( pageSize ); } } 
                                />
                            </div>

                        </Card>

                    </Col>

                </Row>

            </div>

            <Modal
                title="Expedientes asignados" 
                open={ isModalVisible } 
                maskClosable={false} 
                centered 
                footer={null} 
                onCancel={() => { setIsModalVisible(false); setReferencia( '' ); setDataExpediente( [] ); setPageExpediente(1); }}
                width={'calc(100vw - 110px)'}
            >
                
                <div className="table-responsive">

                    <Table
                        columns={ columnsExpediente }
                        dataSource={ dataExpediente }
                        pagination={ false }
                        className="ant-border-space"
                        loading={ loadingExpediente }
                    />

                    <div style={{ textAlign: 'center', padding: '15px' }} >
                        <Pagination 
                            current={ pageExpediente }
                            pageSize={ rowsPerPageExpediente }
                            total={ recordsCountExpediente } 
                            onChange={ ( page, pageSize ) => { setPageExpediente( page ); setRowsPerPageExpediente( pageSize ); } } 
                        />
                    </div>
        
                </div>
                            
            </Modal>
            
            <Modal
                title="Causas penales asignadas" 
                open={ isModalVisibleCausaPenal } 
                maskClosable={false} 
                centered 
                footer={null} 
                onCancel={() => { setIsModalVisibleCausaPenal(false); setReferencia( '' ); setDataCausaPenal( [] ); setPageCausaPenal(1); }}
                width={'calc(100vw - 110px)'}
            >
                
                <div className="table-responsive">

                    <Table
                        columns={ columnsCausaPenal }
                        dataSource={ dataCausaPenal }
                        pagination={ false }
                        className="ant-border-space"
                        loading={ loadingCausaPenal }
                    />

                    <div style={{ textAlign: 'center', padding: '15px' }} >
                        <Pagination 
                            current={ pageCausaPenal }
                            pageSize={ rowsPerPageCausaPenal }
                            total={ recordsCountCausaPenal } 
                            onChange={ ( page, pageSize ) => { setPageCausaPenal( page ); setRowsPerPageCausaPenal( pageSize ); } } 
                        />
                    </div>
        
                </div>
                            
            </Modal>
        </>
    );
    
}
