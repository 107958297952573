export const columnsApikeys = [
    {
        title: "Nombre",
        dataIndex: "nombre",
        key: "nombre",
        width: "10%",
    },

    {
        title: "Api key",
        dataIndex: "apikey",
        key: "apikey",
        width: "10%",
    },
    {
        title: "Dominios",
        dataIndex: "dominios",
        key: "dominios",
        width: "30%",
    },
    {
        title: "Editar",
        dataIndex: "edit",
        key: "edit",
        width: "5%",
    },
    {
        title: "",
        dataIndex: "actions",
        key: "actions",
        width: "5%",
    },
];