import React, { useEffect, useState } from 'react';

import { Card, Col, Row, Typography, Timeline, Button, Modal, Table, Spin } from "antd";

import { BarsOutlined, FileFilled, UserOutlined, FileTextOutlined, DownloadOutlined } from "@ant-design/icons";

import Paragraph from "antd/lib/typography/Paragraph";

import Echart from "../components/chart/EChart";
import LineChart from "../components/chart/LineChart";

import moment from 'moment';

import { columnsUltimasConsultas } from '../helpers/columnsUltimasConsultas';

import { ObtenerDashboard } from '../actions/dashboardActions';

const { Title, Text } = Typography;

export const Dashboard = () => {

    const [isModalVisible, setIsModalVisible] = useState(false);
    const [loading, setLoading] = useState(true);

    const [ultimasConsultas, setUltimasConsultas] = useState( [] );
    const [documento, setDocumento] = useState('');

    const [timelineList, setTimelineList] = useState( [] );
    const [graficaExpedientesDistrito, setGraficaExpedientesDistrito] = useState( [] );
    const [graficaExpedientesFecha, setGraficaExpedientesFecha] = useState( [] );

    const [anio, setAnio] = useState( 0 );

    const [data, setData] = useState({ 
        documentosAlmacenados : 0,
        expedientesAsignados: 0,
        usuariosRegistrados: 0,
        ultimasConsultas: [],
        ultimosExpedientesAutorizados: [],
        expedientesDistrito: [],
        expedientesFecha: [],
    });

    const handleClose = () => {
        
        setDocumento( '' );
        setIsModalVisible( false );

    }

    const handleVerDocumento = ( doc, isODT ) => {
       
        setDocumento( doc ); 
        setIsModalVisible( true );

    }

    useEffect(() => {
     
        async function Obtener(){

            setLoading( true );

            await ObtenerDashboard().then( response => {
                
                if( response ){

                    if( response.status === 200 ){

                        if( response.data ){

                            setTimeout(() => {
                            
                                setData( response.data );
                                
                                var data = response?.data?.ultimasConsultas.map( ( elem, index ) => {

                                    return {
                                        key: index,
                                        persona: 
                                        <h6>
                                            { elem.persona } <br/>
                                            <span style={{ fontSize: '12px', color: '#6A6A69' }}>
                                                { elem.correoElectronico }
                                            </span>
                                        </h6>,
                                        numeroExpediente: <Title level={5}>{ elem.numeroExpediente }</Title>,
                                        fecha: <Title level={5}>{ elem.fecha ? moment( elem.fecha ).format('DD-MM-YYYY hh:mm a') : '' }</Title>,
                                        actions: 
                                            elem.extension === '.odt'
                                            ?
                                                <Button 
                                                    type="link"
                                                    href={ elem.enlaceDocumento }
                                                    icon={ <DownloadOutlined style={{ fontSize: '25px' }} />  } 
                                                    size='large' 
                                                />
                                            :
                                                <Button 
                                                    type="link"
                                                    onClick={ () => handleVerDocumento( elem.enlaceDocumento, elem.extension === '.odt' ) } 
                                                    icon={ <FileFilled style={{ fontSize: '25px' }} /> } 
                                                    size='large' 
                                                />
                                    };
                                });

                                var data2 = response?.data?.ultimosExpedientesAutorizados.map( ( elem ) => {
                                    return {
                                        expediente: elem.numeroExpediente,
                                        juzgado: elem.juzgado,
                                        persona: elem.persona,
                                    };
                                });

                                setUltimasConsultas( data );
                                setTimelineList( data2 );
                                setGraficaExpedientesDistrito( response?.data?.expedientesDistrito );
                                setGraficaExpedientesFecha( response?.data?.expedientesFecha );
                                setAnio( response?.data?.anio ?? 0 );

                                setLoading( false );

                            }, 500);
                            
                        }
                    }
                    else{
                        setLoading( false );
                    }
                }
                else{
                    setLoading( false );
                }
            });
        }
    
        Obtener();
    
    }, [])

    return (
        <>
            <div className="layout-content">

                <Row className="rowgap-vbox" gutter={[24, 0]}>

                    <Col xs={24} sm={24} md={12} lg={8} xl={8} className="mb-24" >

                        <Card bordered={false} className="criclebox" loading={ loading }>

                            <div className="number">

                                <Row align="middle" gutter={[24, 0]}>

                                    <Col xs={18}>
                                        <span>Expedientes autorizados</span>
                                        <Title level={3}>
                                            { parseInt( data.expedientesAsignados ?? '0' ).toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') }
                                        </Title>
                                    </Col>

                                    <Col xs={6}>
                                        <div className="icon-box">{ <BarsOutlined /> }</div>
                                    </Col>

                                </Row>

                            </div>

                        </Card>

                    </Col>

                    <Col xs={24} sm={24} md={12} lg={8} xl={8} className="mb-24" >

                        <Card bordered={false} className="criclebox" loading={ loading }>

                            <div className="number">

                                <Row align="middle" gutter={[24, 0]}>

                                    <Col xs={18}>
                                        <span>Usuarios registrados</span>
                                        <Title level={3}>
                                            { parseInt( data.usuariosRegistrados ?? '0' ).toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') }
                                        </Title>
                                    </Col>

                                    <Col xs={6}>
                                        <div className="icon-box">{ <UserOutlined /> }</div>
                                    </Col>

                                </Row>

                            </div>

                        </Card>

                    </Col>

                    <Col xs={24} sm={24} md={12} lg={8} xl={8} className="mb-24" >

                        <Card bordered={false} className="criclebox" loading={ loading }>

                            <div className="number">

                                <Row align="middle" gutter={[24, 0]}>

                                    <Col xs={18}>
                                        <span>Documentos almacenados</span>
                                        <Title level={3}>
                                            { parseInt( data.documentosAlmacenados ?? '0' ).toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') }
                                        </Title>
                                    </Col>

                                    <Col xs={6}>
                                        <div className="icon-box">{ <FileTextOutlined /> }</div>
                                    </Col>

                                </Row>

                            </div>

                        </Card>

                    </Col>

                </Row>

                <Row gutter={[24, 0]}>

                    <Col xs={24} sm={24} md={12} lg={12} xl={16} className="mb-24">

                        <Card bordered={false} className="criclebox cardbody h-full">
                            
                            <div className="project-ant" style={{ paddingBottom: '1em' }}>
                                <div>
                                    <Title level={5}>Ultimas consultas</Title>
                                </div>
                            </div>

                            <div className="table-responsive" style={{marginTop: '20px'}}>

                                <Table
                                    className="ant-border-space"
                                    columns={ columnsUltimasConsultas }
                                    dataSource={ ultimasConsultas }
                                    pagination={ false }
                                    loading={ loading }
                                />
                            
                            </div>
                            
                        </Card>

                    </Col>

                    <Col xs={24} sm={24} md={12} lg={12} xl={8} className="mb-24">

                        <Card bordered={false} className="criclebox h-full">

                            <div className="timeline-box">

                                <Title level={5}>Ultimos expedientes autorizados</Title>
                                <Paragraph className="lastweek" style={{ marginBottom: 24 }}> </Paragraph>

                                <Timeline className="timelinelist">

                                    {
                                        loading
                                        &&
                                        <div style={{
                                            margin: '20px 0',
                                            marginBottom:' 20px',
                                            padding: '30px 50px',
                                            textAlign: 'center',
                                            borderRadius: '4px',
                                        }}>
                                            <Spin size='large' />
                                        </div>
                                    }

                                    {
                                        timelineList.map( ( data, index ) => (
                                            <Timeline.Item key={index}>
                                                <Title level={5}>
                                                    Expediente: { data.expediente }
                                                </Title>
                                                <Text><strong>Solicitante: { data.persona }</strong></Text> <br />
                                                <Text>{ data.juzgado }</Text>
                                            </Timeline.Item>
                                        ))
                                    }

                                </Timeline>

                            </div>

                        </Card>
                        
                    </Col>

                </Row>

                <Row gutter={[24, 0]}>

                    <Col xs={24} sm={24} md={24} lg={24} xl={24} className="mb-24">
                        <Card bordered={false} className="criclebox h-full">
                            <Echart graficaExpedientesDistrito={ graficaExpedientesDistrito } loading={ loading } />
                        </Card>
                    </Col>

                    <Col xs={24} sm={24} md={24} lg={24} xl={24} className="mb-24">
                        <Card bordered={false} className="criclebox h-full">
                            <LineChart anio={ anio } graficaExpedientesFecha={ graficaExpedientesFecha } loading={ loading } />
                        </Card>
                    </Col>

                </Row>

                <Modal 
                    title="Documento" 
                    open={ isModalVisible } 
                    centered 
                    footer={null} 
                    onCancel={ handleClose }
                    width={900}
                >
                    { 
                        documento
                        &&
                        <iframe
                            title='iframe documento'
                            src={ documento }
                            style={{ width: '100%', height: '500px' }}
                        /> 
                    }
                </Modal>

            </div>

        </>
    );
    
}
