import { useEffect, useState } from "react";

import { useDispatch } from "react-redux";

import { Row, Col, Button, Modal } from "antd";

import { PoweroffOutlined, BarsOutlined, ExclamationCircleOutlined } from "@ant-design/icons";

import { Logout } from "../actions/authActions";

import { ModalTokenExpired } from "./ModalTokenExpired";

function Header({ onPress }) {

    const dispatch = useDispatch();

    const [loading, setLoading] = useState(false);

    const salir = () => {

        Modal.confirm({
            title: 'Confirmación',
            icon: <ExclamationCircleOutlined />,
            content: '¿Desea salir de la plataforma?',
            centered: true,
            onOk() {
                setLoading(true);

                setTimeout(() => {
                    dispatch( Logout() );
                    setLoading(false);
                }, 2000);
            },
            onCancel() { },
        });
    
    }

    useEffect(() => window.scrollTo(0, 0));

    return (
        <>
            <ModalTokenExpired />

            <Row gutter={[24, 0]}>

                <Col span={24} md={6}> </Col> 

                <Col span={24} md={18} className="header-control">

                      <Button onClick={ salir } type="link" icon={ <PoweroffOutlined /> } size='large' loading={loading} />

                      <Button
                        type="link"
                        className="sidebar-toggler"
                        onClick={() => onPress()}
                      >
                          <BarsOutlined />
                      </Button>

                  </Col>

            </Row>

        </>
    );
}

export default Header;
