import React, { useEffect, useState } from "react";

import { Row, Modal, Col, Card, Table, Button, Typography, Pagination } from "antd";

import { FileOutlined } from "@ant-design/icons";

import { columnsNotificaciones } from "../helpers/columnsNotificaciones";
import { columnsNotificacionesDocumentos } from "../helpers/columnsNotificacionesDocumentos";

import { ObtenerDocumentosNotificacion, ObtenerNotificaciones } from "../actions/notificacionActions";

const { Title } = Typography;

export const Notificaciones = () => {

    const [isModalVisible, setIsModalVisible] = useState( false );
    const [isModalDocumentoVisible, setIsModalDocumentoVisible] = useState( false );

    const [page, setPage] = useState( 1 );
    const [rowsPerPage, setRowsPerPage] = useState( 10 );
    const [recordsCount, setRecordsCount] = useState( 0 );

    const [data, setData] = useState( [] );
    const [dataDocumentos, setDataDocumentos] = useState( [] );

    const [documento, setDocumento] = useState( '' );
    const [loading, setLoading] = useState( false );

    const [pageDocumentos, setPageDocumentos] = useState( 1 );
    const [rowsPerPageDocumentos, setRowsPerPageDocumentos] = useState( 5 );
    const [recordsCountDocumentos, setRecordsCountDocumentos] = useState( 0 );

    const [loadingDocumentos, setLoadingDocumentos] = useState( false );

    const [idNotificacion, setIdNotificacion] = useState( '' );
    const [filtroAplicadoDocumentos, setFiltroAplicadoDocumentos] = useState( false );

    const handleVerDocumento = ( doc ) => {
       
        setDocumento( doc ); 
        setIsModalDocumentoVisible( true );

    }

    const handleBuscarDocumentos = async ( id ) => {

        setIsModalVisible( true );
        setFiltroAplicadoDocumentos( true );
        setIdNotificacion( id );
        setPageDocumentos( 1 );
        setRecordsCountDocumentos( 0 );
    }

    const handleClearBuscarDocumentos = async ( ) => {

        setIsModalVisible( false );
        setFiltroAplicadoDocumentos( false );
        setIdNotificacion( '' );
        setPageDocumentos( 1 );
        setRecordsCountDocumentos( 0 );

    }

    useEffect(() => {

        async function Obtener() {

            setLoading(true);
            setData([]);

            await ObtenerNotificaciones({ page: page - 1, rowsPerPage,}).then(response => {

                if ( response ) {
    
                    if ( response.status === 200 ) {
    
                        if ( response.data ) {
    
                            setTimeout(() => {
    
                                setRecordsCount(response.data.recordsCount ?? 0);

                                var data = response?.data?.records.map( ( elem ) => {
                                    return {
                                        key: elem.idNotificacion,
                                        folio: <Title level={5}>{elem.folio !== 0 ? elem.folio : ''}</Title>,
                                        expediente: <Title level={5}>{elem.numeroExpediente}</Title>,
                                        juzgado: <Title level={5}>Juzgado {elem.tipoJuzgado} Primera Instancia en Materia {elem.materia} del distrito Judicial de {elem.distritoJudicial}</Title>,
                                        actor: <Title level={5}>{elem.actor}</Title>,
                                        demandado: <Title level={5}>{elem.demandado}</Title>,
                                        numeroDocumentos: <Title level={5} style={{ textAlign: 'center' }}><Button onClick={ () => handleBuscarDocumentos( elem.idNotificacion ) } type="default" disabled= { elem.numeroDocumentos === 0 }>{ elem.numeroDocumentos }</Button></Title>,
                                    }
                                })
                            
                                setLoading( false );
                                setData( data );
    
                            }, 1000);

                        }

                    }

                }

            });
        }
  
        Obtener();

    }, [ page, rowsPerPage ])

    useEffect(() => {

        async function Obtener() {

            setLoadingDocumentos( true );
            setDataDocumentos( [] );

            await ObtenerDocumentosNotificacion({ page: pageDocumentos - 1, rowsPerPage: rowsPerPageDocumentos, idNotificacion }).then(response => {

                if ( response ) {

                    if ( response.status === 200 ) {

                        if ( response.data ) {

                            setTimeout(() => {

                                setRecordsCountDocumentos(response.data.recordsCount ?? 0);

                                var data = response?.data?.records.map( ( elem ) => {
                                    return {
                                        key: elem.idDocumento,
                                        documento: <Title level={5}>{elem.nombreDocumento}</Title>,
                                        tipo: <Title level={5}>{elem.tipoDocumento}</Title>,
                                        extension: <Title level={5}>{elem.extension}</Title>,
                                        fecha: <Title level={5}>{elem.fecha}	</Title>,
                                        actions:
                                            elem.extension === '.odt'
                                            ?
                                                <Button href={ elem.enlaceDocumento } type="link"> 
                                                    <FileOutlined style={{ fontSize: '20px' }} /> 
                                                </Button>
                                            :
                                                <Button onClick={() => handleVerDocumento( elem.enlaceDocumento, elem.extension )} type="link"> 
                                                    <FileOutlined style={{ fontSize: '20px' }} /> 
                                                </Button>
                                    }
                                })
                                   
                                setLoadingDocumentos( false );
                                setDataDocumentos( data );

                            }, 1000);

                        }

                    }

                }

            });
        }

        if ( filtroAplicadoDocumentos ) {
            Obtener();
        }

    }, [ pageDocumentos, rowsPerPageDocumentos, filtroAplicadoDocumentos, idNotificacion ])

    return (
        <>

            <div className="tabled">

                <Row gutter={[24, 0]}>

                    <Col xs="24" xl={24} >

                        <Card
                            bordered={false}
                            className="criclebox tablespace mb-24"
                            style={{ width: '100%' }}
                        >
                            <div className="table-responsive" style={{marginTop: '20px'}}>

                                <Table
                                    className="ant-border-space"
                                    dataSource={ data }
                                    pagination={ false }
                                    columns={ columnsNotificaciones }
                                    loading={ loading }
                                />
                            
                            </div>

                            <div style={{ float: 'left', padding: '15px' }} >
                                <Title level={5}>Total de notificaciones: { recordsCount }</Title>
                            </div>

                            <div style={{ float: 'right', padding: '15px' }} >
                                <Pagination 
                                    current={ page }
                                    pageSize={ rowsPerPage }
                                    total={ recordsCount } 
                                    onChange={ ( page, pageSize ) => { setPage(page); setRowsPerPage(pageSize); } } 
                                />
                            </div>

                        </Card>

                    </Col>

                </Row>

            </div>

            <Modal 
                title="Documentos" 
                open={ isModalVisible } 
                maskClosable={false} 
                centered 
                footer={null} 
                onCancel={ handleClearBuscarDocumentos }
                width={ 1000 }
            >
                
                <div className="table-responsive">

                    <Table
                        className="ant-border-space"
                        columns={ columnsNotificacionesDocumentos }
                        dataSource={ dataDocumentos }
                        pagination={ false }
                        loading={ loadingDocumentos }
                    />
                
                </div>

                <Row gutter={[24, 0]} style={{ padding: '1em' }} >
                    <Col span={12} style={{ textAlign: 'left' }}>
                        <Title level={5}>Total de documentos: { recordsCountDocumentos }</Title>
                    </Col>

                    <Col span={12} style={{ textAlign: 'right' }} >
                        <Pagination 
                            current={ pageDocumentos }
                            pageSize={ rowsPerPageDocumentos }
                            total={ recordsCountDocumentos } 
                            onChange={ ( page, pageSize ) => { setPageDocumentos( page ); setRowsPerPageDocumentos( pageSize ); } } 
                        />
                    </Col>
                </Row>
                            
            </Modal>

            <Modal 
                title="Documento" 
                open={ isModalDocumentoVisible } 
                maskClosable={false} 
                centered 
                footer={null} 
                onCancel={() => { setIsModalDocumentoVisible(false); }}
                width={800}
            >
                <iframe
                    title='iframe documento'
                    src={documento}
                    style={{ width: '100%', height: '500px' }}
                >

                </iframe>
            </Modal>

        </>
    );

}