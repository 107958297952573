export const columnsNotificacionesDocumentos = [
    {
        title: "Documento",
        dataIndex: "documento",
        key: "documento",
        width: 10,
    },
    {
        title: "Tipo",
        dataIndex: "tipo",
        key: "tipo",
        width: 10,
    },
    {
        title: "Extensión",
        dataIndex: "extension",
        key: "extension",
        width: 10,
    },
    {
        title: "Fecha",
        dataIndex: "fecha",
        key: "fecha",
        width: 15,
    },
    {
        title: "",
        dataIndex: "actions",
        key: "actions",
        width: 15,
    }
];