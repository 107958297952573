import React, { useEffect, useState } from 'react';

import { useDispatch } from 'react-redux';

import { Button, Modal } from 'antd';

import { Logout, ValidarSesion } from '../actions/authActions';

export const ModalTokenExpired = () => {

    const dispatch = useDispatch();

    const [open, setOpen] = useState( false );

    const [loading, setLoading] = useState( false );

    const IniciarSesion = () => {

        setLoading( true );

        setTimeout(() => {

            dispatch( Logout() );

        }, 1000);

    }

    useEffect(() => {
       
        async function Obtener(){

            await ValidarSesion().then( response => {

                if( response ){

                    const { success } = response.data;

                    if( !success ){
                        setOpen(true);
                    }
                }
            });

        }

        Obtener();

    }, [ dispatch ] );

    return (
        <Modal 
            title="El tiempo de sesión ha expirado" 
            open={ open } 
            closable={false}
            centered 
            footer={[
                <Button key="iniciarSesion" loading={ loading } onClick={ IniciarSesion }>
                    Aceptar
                </Button>
            ]} 
        >   
            Por seguridad es necesario volver a iniciar sesión
        </Modal>
    )
}
