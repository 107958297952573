import httpClientApiKey from "../services/httpClientApiKey";
import httpClientToken from "../services/httpClientToken";

import { login, logout, authError } from "../store/slices/authSlice";

export const Login = ( params, setLoading ) => {

    return async ( dispatch ) => {

        await httpClientApiKey.post('/Api/Auth/Login', params)
        .then( response => {
        
            const { data } = response;

            if( data.success ){

                setTimeout(() => {
                
                    window.localStorage.setItem("data", JSON.stringify(data));

                    dispatch( login( data ) );

                    setLoading( false );

                }, 1000);
                
            }
            else {

                setTimeout(() => {
                    
                    dispatch( authError( data.message ?? '' ) );

                    setLoading( false );

                }, 1000);
                
            }
            
        })
        .catch( ( error ) => {

            if( error.response ){

                const { message } = error.response?.data;

                dispatch( authError( message ?? '' ) );
                
                setLoading( false );

            }            
            
        });   
          
    }
}

export const Logout = ( ) => {

    return async ( dispatch ) => {
        
        window.localStorage.clear();

        dispatch( logout() );

    }         
        
}

export const ValidarSesion = () => {

    return new Promise( (resolve, eject) => {

        const data = JSON.parse(window.localStorage.getItem('data'));

        if( data ){
            const { token } = data;

            httpClientToken.post('/Api/Auth/ValidarSesion/', null, token)
            .then( response => {
                if( response.status === 200 ){
                    resolve( response );
                }
            })
            .catch( (error) => {
                resolve( error.response );
            });
        }

    });

}