export const columnsUsuario = [
    {
        title: "Nombre",
        dataIndex: "nombre",
        key: "nombre",
        width: "20%",
    },
    {
        title: "Apellido 1",
        dataIndex: "apellido1",
        key: "Apellido1",
        width: "20%",
    },
    {
        title: "Apellido 2",
        dataIndex: "apellido2",
        key: "apellido2",
        width: "20%",
    },
    {
        title: "Correo Electrónico",
        dataIndex: "correoElectronico",
        key: "correoElectronico",
        width: "20%",
    },
    {
        title: "Telefono celular",
        dataIndex: "telefonoCelular",
        key: "telefonoCelular",
        width: "15%",
    },
    {
        title: "Número de expedientes",
        dataIndex: "numeroExpedientes",
        key: "numeroExpedientes",
        width: "5%",
    },
    {
        title: "Número de causas",
        dataIndex: "numeroCausas",
        key: "numeroCausas",
        width: "5%",
    },
];