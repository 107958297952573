let eChart = {
    series: [
        {
            name: "Expedientes autorizados",
            data: [],
            color: "#fff",
        },
    ],
    options: {
        chart: {
            type: "bar",
            width: "100%",
            height: "auto",
            toolbar: {
              show: false,
            },
        },
        plotOptions: {
            bar: {
              horizontal: false,
              columnWidth: "55%",
              borderRadius: 5,
            },
        },
        dataLabels: {
            enabled: true,
            style: {
                fontSize: '14px',
                fontFamily: 'Helvetica, Arial, sans-serif',
                fontWeight: 'bold',
                colors: ["#6A6A69"]
            },
        },
        stroke: {
            show: true,
            width: 1,
            colors: ["transparent"],
        },
        grid: {
            show: false,
            borderColor: "#ccc",
            strokeDashArray: 2,
        },
        xaxis: {
            categories: [],
            labels: {
                show: true,
                align: "right",
                minWidth: 0,
                maxWidth: 160,
                style: {
                    colors: [
                        "#fff",
                        "#fff",
                        "#fff",
                        "#fff",
                        "#fff",
                        "#fff",
                        "#fff",
                        "#fff",
                        "#fff",
                        "#fff",
                    ],
                },
            },
        },
        yaxis: {
            labels: {
                show: true,
                align: "right",
                minWidth: 0,
                maxWidth: 160,
                style: {
                    colors: [
                        "#fff",
                        "#fff",
                        "#fff",
                        "#fff",
                        "#fff",
                        "#fff",
                        "#fff",
                        "#fff",
                        "#fff",
                        "#fff",
                    ],
                },
            },
        },
        tooltip: {
            enabled: false,
            y: {
                formatter: function (val) {
                    return val;
                },
            },
        },
    },
};

export default eChart;
