export const columnsCausaPenal = [
    {
        title: "NIC",
        dataIndex: "nic",
        key: "nic",
        width: 10,
    },
    {
        title: "NUC",
        dataIndex: "nuc",
        key: "nuc",
        width: 10,
    },
    {
        title: "Juzgado",
        dataIndex: "juzgado",
        key: "juzgado",
        width: 20,
    },
    {
        title: "Imputado",
        dataIndex: "imputado",
        key: "imputado",
        width: 15,
    },
    {
        title: "Victima u ofendido",
        dataIndex: "victima_ofendido",
        key: "victima_ofendido",
        width: 15,
    }
];