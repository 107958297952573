export const columnsUltimasConsultas = [
    {
        title: "Persona",
        dataIndex: "persona",
        key: "persona",
        width: 30,
    },
    {
        title: "Número de expediente",
        dataIndex: "numeroExpediente",
        key: "numeroExpediente",
        width: 10,
    },
    {
        title: "Fecha de consulta",
        dataIndex: "fecha",
        key: "fecha",
        width: 10,
    },
    {
        title: "Documento",
        dataIndex: "actions",
        key: "actions",
        width: 5,
    }
];