import ReactApexChart from "react-apexcharts";

import { Spin, Typography } from "antd";

import lineChart from "./configs/lineChart";

const { Title } = Typography;

function LineChart({ graficaExpedientesFecha, loading, anio }) {

    let arrayCategorias = [];
    let arrayData = [];

    graficaExpedientesFecha?.forEach( ( elem ) => { 
        
        switch ( elem.mes ) {
            case 1:
                arrayCategorias.push( 'Ene' );
                break;
            case 2:
                arrayCategorias.push( 'Feb' );
                break;
            case 3:
                arrayCategorias.push( 'Mar' );
                break;
            case 4:
                arrayCategorias.push( 'Abr' );
                break;
            case 5:
                arrayCategorias.push( 'May' );
                break;
            case 6:
                arrayCategorias.push( 'Jun' );
                break;
            case 7:
                arrayCategorias.push( 'Jul' );
                break;
            case 8:
                arrayCategorias.push( 'Ago' );
                break;
            case 9:
                arrayCategorias.push( 'Sep' );
                break;
            case 10:
                arrayCategorias.push( 'Oct' );
                break;
            case 11:
                arrayCategorias.push( 'Nov' );
                break;
            case 12:
                arrayCategorias.push( 'Dic' );
                break;
            default:
                arrayCategorias.push( '' );
                break;
        }
        
        arrayData.push( elem.count );
    });

    lineChart.series[0].data = arrayData;
    lineChart.options.xaxis.categories = arrayCategorias;

    return (
        <>
            <div className="linechart" style={{ paddingBottom: '1em' }}>
                <div>
                    <Title level={5}>Expedientes autorizados por mes { anio !== 0 ? '(Año ' + anio + ')' : '' }</Title>
                </div>
            </div>

            {
                loading
                ?
                    <div style={{
                        margin: '20px 0',
                        marginBottom:' 20px',
                        padding: '30px 50px',
                        textAlign: 'center',
                        borderRadius: '4px',
                    }}>
                        <Spin size='large' />
                    </div>
                :
                    <ReactApexChart
                        className="full-width"
                        options={lineChart.options}
                        series={lineChart.series}
                        type="area"
                        height={350}
                        width={"100%"}
                    />
            }
        </>
    );
}

export default LineChart;
