import { Provider } from "react-redux";

import { AppRouter } from "./routers/AppRouter";

import { store } from "./store";

const App = () => {

    return (

        <div className="App">

            <Provider store={ store }>

                <AppRouter />

            </Provider>

        </div>
        
    );

}

export default App;