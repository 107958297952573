import React, { useEffect, useState } from "react";

import { Row, Modal, Col, Card, Table, Button, Typography, Pagination, Input, Select } from "antd";

import { ClearOutlined, FileOutlined, SearchOutlined, DownloadOutlined } from "@ant-design/icons";

import { columnsExpedienteVirtual } from "../helpers/columnsExpedienteVirtual";
import { columnsExpedienteVirtualDocumentos } from "../helpers/columnsExpedienteVirtualDocumentos";

import { ObtenerJuzgados } from "../actions/juzgadoActions";
import { ObtenerMateria } from "../actions/materiaAction";
import { ObtenerDistritoJudicial } from "../actions/distritoJudicialActions";

import { BuscarExpedienteVirtual, ObtenerDocumentosExpedienteVirtual, ObtenerExpedienteVirtual } from "../actions/expedienteActions";

const { Title } = Typography;

export const ExpedienteVirtual = () => {

    const [isModalVisible, setIsModalVisible] = useState( false );
    const [isModalDocumentoVisible, setIsModalDocumentoVisible] = useState( false );

    const [page, setPage] = useState( 1 );
    const [rowsPerPage, setRowsPerPage] = useState( 10 );
    const [recordsCount, setRecordsCount] = useState( 0 );

    const [pageDocumentos, setPageDocumentos] = useState( 1 );
    const [rowsPerPageDocumentos, setRowsPerPageDocumentos] = useState( 10 );
    const [recordsCountDocumentos, setRecordsCountDocumentos] = useState( 0 );
    
    const [data, setData] = useState( [] );
    const [dataDocumentos, setDataDocumentos] = useState( [] );
    
    const [documento, setDocumento] = useState( '' );
    const [loading, setLoading] = useState( false );

    const [loadingDocumentos, setLoadingDocumentos] = useState( false );

    const [juzgado, setJuzgado] = useState( 0 );
    const [juzgadosArray, setJuzgadosArray] = useState( [] );
    
    const [materia, setMateria] = useState( 0 );
    const [materiaArray, setMateriaArray] = useState( [] );
    
    const [distritoJudicial, setDistritoJudicial] = useState( 0 );
    const [distritoJudicialArray, setDistritoJudicialArray] = useState( [] );
    
    const [numExpediente, setNumExpediente] = useState( '' );
    const [idExpediente, setIdExpediente] = useState( '' );

    const [filtroAplicado, setFiltroAplicado] = useState( false );
    const [filtroAplicadoDocumentos, setFiltroAplicadoDocumentos] = useState( false );

    const handleLimpiarFiltro = () => {

        setFiltroAplicado( false );
        
        setNumExpediente( '' );
        setJuzgado( 0 );
        setMateria( 0 );
        setDistritoJudicial( 0 );

        setPage( 1 );
        setRecordsCount( 0 );
    }

    const handleBuscar = () => {

        setFiltroAplicado( true )
        setPage( 1 );

    }

    const handleBuscarDocumentos = async ( id ) => {

        setIsModalVisible( true );
        setFiltroAplicadoDocumentos( true );
        setIdExpediente( id );
        setPageDocumentos( 1 );

    }

    const handleClearBuscarDocumentos = async ( ) => {

        setIsModalVisible( false );
        setFiltroAplicadoDocumentos( false );
        setIdExpediente( '' );
        setPageDocumentos( 1 );

    }

    const handleVerDocumento = ( doc ) => {
       
        setDocumento( doc ); 
        
        setIsModalDocumentoVisible( true );

    }

    useEffect(() => {

        async function Obtener() {

            await ObtenerDistritoJudicial().then( response => {
   
                if ( response ) {

                    if ( response.status === 200 ) {

                        if ( response.data ){
                            setDistritoJudicialArray( response.data );
                        }

                    }

                }

            });

        }

        Obtener();

    }, [])
    
    useEffect(() => {

        async function Obtener() {

            await ObtenerMateria().then( response => {

                if ( response ) {

                    if ( response.status === 200) {

                        if ( response.data ) {
                            setMateriaArray( response.data );
                        }

                    }

                }

            });

        }

        Obtener();

    }, [])
    
    useEffect(() => {

        async function Obtener() {

            if( materia !== 0 ){

                const params ={
                    idMateria: materia,
                    idDistritoJudicial: distritoJudicial
                }

                await ObtenerJuzgados( params ).then(response => {
    
                    if ( response ) {
    
                        if ( response.status === 200 ) {
    
                            if ( response.data ) {
                                setJuzgadosArray( response.data );
                            }
    
                        }
    
                    }
    
                });
            }

        }

        if( materia !== 0 && distritoJudicial !== 0 ){
            Obtener();
        }

    }, [ materia, distritoJudicial ])

    useEffect(() => {

        async function Obtener() {

            setLoading(true);
            setData([]);

            await ObtenerExpedienteVirtual({ page: page - 1, rowsPerPage }).then(response => {

                if ( response ) {

                    if ( response.status === 200 ) {

                        if ( response.data ) {

                            setTimeout(() => {

                                setRecordsCount(response.data.recordsCount ?? 0);

                                var data = response?.data?.records.map( ( elem ) => {
                                    return {
                                        key: elem.idExpediente,
                                        folio: <Title level={5}>{elem.folio !== '0' ? elem.folio : '' }</Title>,
                                        expediente: <Title level={5}>{elem.numeroExpediente}</Title>,
                                        juzgado: <Title level={5}>Juzgado {elem.tipoJuzgado} Primera Instancia en Materia {elem.materia } del distrito Judicial de {elem.distritoJudicial}</Title>,
                                        actor: <Title level={5}>{elem.actor}</Title>,
                                        demandado: <Title level={5}>{elem.demandado}</Title>,
                                        numeroDocumentos: <Title level={5} style={{ textAlign: 'center' }}><Button onClick={ () => handleBuscarDocumentos( elem.idExpediente ) } type="default" disabled={ elem.numeroDocumentos === 0 }>{elem.numeroDocumentos}</Button></Title>,                                    
                                    }
                                })
                                   
                                setLoading( false );
                                setData( data );

                            }, 1000);

                        }

                    }

                }

            });
        }
        if ( !filtroAplicado ) {
            Obtener();
        }

    }, [page, rowsPerPage, filtroAplicado])

    useEffect(() => {

        async function Obtener() {

            setLoading(true);
            setData([]);

            await BuscarExpedienteVirtual({ page: page - 1, rowsPerPage, numeroExpediente: numExpediente, idMateria: materia, idDistritoJudicial: distritoJudicial, idTipoJuzgado: juzgado }).then(response => {

                if ( response ) {
    
                    if ( response.status === 200 ) {
    
                        if ( response.data ) {
    
                            setTimeout(() => {
    
                                setRecordsCount(response.data.recordsCount ?? 0);

                                var data = response?.data?.records.map( ( elem ) => {
                                    return {
                                        key: elem.idExpediente,
                                        folio: <Title level={5}>{elem.folio !== '0' ? elem.folio : '' }</Title>,
                                        expediente: <Title level={5}>{elem.numeroExpediente}</Title>,
                                        juzgado: <Title level={5}>Juzgado {elem.tipoJuzgado} Primera Instancia en Materia {elem.materia} del distrito Judicial de {elem.distritoJudicial}</Title>,
                                        actor: <Title level={5}>{elem.actor}</Title>,
                                        demandado: <Title level={5}>{elem.demandado}</Title>,
                                        numeroDocumentos: <Title level={5} style={{ textAlign: 'center' }}><Button onClick={() => handleBuscarDocumentos( elem.idExpediente ) } type="default" disabled= { elem.numeroDocumentos === 0 }>{elem.numeroDocumentos}</Button></Title>,
                                    }
                                });
                                   
                                setLoading( false );
                                setData( data );
    
                            }, 1000);
    

                        }

                    }

                }

            });
        }
       
        if ( filtroAplicado ) {
            Obtener();
        }

    }, [ page, rowsPerPage, filtroAplicado, distritoJudicial, materia, numExpediente, juzgado ])

    useEffect(() => {

        async function Obtener() {

            setLoadingDocumentos( true );
            setDataDocumentos( [] );

            await ObtenerDocumentosExpedienteVirtual({ page: pageDocumentos - 1, rowsPerPage: rowsPerPageDocumentos, idExpediente }).then(response => {

                if ( response ) {

                    if ( response.status === 200 ) {

                        if ( response.data ) {

                            setTimeout(() => {

                                setRecordsCountDocumentos(response.data.recordsCount ?? 0);
                                
                                var data = response?.data?.records.map( ( elem ) => {
                                    return {

                                        key: elem.idDocumento,
                                        documento: <Title level={5}>{elem.nombreDocumento}</Title>,
                                        tipo: <Title level={5}>{elem.tipoDocumento}</Title>,
                                        extension: <Title level={5}>{elem.extension}</Title>,
                                        fecha: <Title level={5}>{elem.fecha}	</Title>,
                                        actions: 
                                            elem.extension === '.odt'
                                                ?
                                                    <Button href={ elem.enlaceDocumento } type="link"> 
                                                        <FileOutlined style={{ fontSize: '20px' }} /> 
                                                    </Button>
                                                :
                                                    <Button onClick={() => handleVerDocumento( elem.enlaceDocumento, elem.extension )} type="link"> 
                                                        <FileOutlined style={{ fontSize: '20px' }} /> 
                                                    </Button>
                                    }
                                })
                                   
                                setLoadingDocumentos( false );
                                setDataDocumentos( data );

                            }, 1000);

                        }

                    }

                }

            });
        }

        if ( filtroAplicadoDocumentos ) {
            Obtener();
        }

    }, [ pageDocumentos, rowsPerPageDocumentos, filtroAplicadoDocumentos, idExpediente ])

    return (
        <>

            <div className="tabled">

                <Row gutter={[24, 0]}>

                    <Col xs="24" xl={24} >

                        <Card
                            bordered={false}
                            className="criclebox tablespace mb-24"
                            style={{ width: '100%', padding: '1em' }}
                        >

                            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} justify='end'>

                                <Col className="gutter-row" span={6}>
                                    <Input placeholder="1/2022" value={numExpediente} onChange={(e) => { setNumExpediente(e.target.value)}} disabled={ filtroAplicado }/>
                                </Col>

                                <Col className="gutter-row" span={6}>
                                    <Select size="large" style={{ width: '100%' }} value={distritoJudicial} onChange={(e) => { setDistritoJudicial(e)}} disabled={ filtroAplicado }>
                                        <Select.Option value={0}>Seleccione una opción</Select.Option>
                                        {
                                            distritoJudicialArray.map((element) => (
                                                <Select.Option key={element.idDistritoJudicial} value={element.idDistritoJudicial}>{element.descripcion}</Select.Option>
                                                
                                            ))
                                        }
                                    </Select>
                                </Col>

                                <Col className="gutter-row" span={6}>
                                    <Select size="large" style={{ width: '100%' }} value={materia} onChange={(e) => {setMateria(e) }} disabled={ distritoJudicial === 0 || filtroAplicado } >
                                        <Select.Option value={0}>Seleccione una opción</Select.Option>
                                        {
                                            materiaArray.map((element) => (
                                                <Select.Option key={element.idMateria} value={element.idMateria}>{element.descripcion}</Select.Option>
                                                
                                            ))
                                        }
                                    </Select>
                                </Col>

                                <Col className="gutter-row" span={6}>
                                    <Select size="large" style={{ width: '100%' }} value={juzgado} onChange={(e) => {setJuzgado(e) }} disabled={ ( materia === 0 && distritoJudicial === 0 ) || filtroAplicado } >
                                        <Select.Option value={0}>Seleccione una opción</Select.Option>
                                    {
                                            juzgadosArray.map((element) => (
                                                <Select.Option key={element.idTipoJuzgado} value={element.idTipoJuzgado }>{element.tipoJuzgado.descripcion}</Select.Option>
                                                
                                            ))
                                        }
                                    </Select>
                                </Col>

                                <Col className="gutter-row" span={6}  >
                                    <Button style={{ fontSize: '14px', width: '100%', marginTop: '1em' }} icon={ filtroAplicado ? <ClearOutlined /> : <SearchOutlined /> } onClick={ () => filtroAplicado ? handleLimpiarFiltro() : handleBuscar() } disabled={(numExpediente === "" && distritoJudicial === 0) && !filtroAplicado} loading={ loading }>
                                        { filtroAplicado ? 'Limpiar' : 'Buscar' }
                                    </Button>
                                </Col>
                                
                            </Row>

                        </Card>
                                
                        <Card
                            bordered={false}
                            className="criclebox tablespace mb-24"
                            style={{ width: '100%' }}
                        >
                            <div className="table-responsive" style={{ marginTop: '20px' }}>

                                <Table
                                    className="ant-border-space"
                                    dataSource={ data }
                                    pagination={ false }
                                    columns={ columnsExpedienteVirtual }
                                    loading={ loading }
                                />

                            </div>

                            <div style={{ float: 'left', padding: '15px' }} >
                                <Title level={5}>Total de expedientes: { recordsCount }</Title>
                            </div>

                            <div style={{ float: 'right', padding: '15px' }} >
                                <Pagination 
                                    current={ page }
                                    pageSize={ rowsPerPage }
                                    total={ recordsCount } 
                                    onChange={ ( page, pageSize ) => { setPage( page ); setRowsPerPage( pageSize ); } } 
                                />
                            </div>

                        </Card>

                    </Col>

                </Row>

            </div>

            <Modal
                title="Documentos"
                open={ isModalVisible }
                maskClosable={ false }
                centered
                footer={ null }
                onCancel={ handleClearBuscarDocumentos }
                width={ 1000 }
            >

                <div className="table-responsive">

                    <Table
                        className="ant-border-space"
                        columns={ columnsExpedienteVirtualDocumentos }
                        dataSource={ dataDocumentos }
                        loading={ loadingDocumentos }
                        pagination={ false }
                    />

                </div>

                <Row gutter={[24, 0]} style={{ padding: '1em' }} >
                    <Col span={12} style={{ textAlign: 'left' }}>
                        <Title level={5}>Total de documentos: { recordsCountDocumentos }</Title>
                    </Col>

                    <Col span={12} style={{ textAlign: 'right' }} >
                        <Pagination 
                            current={ pageDocumentos }
                            pageSize={ rowsPerPageDocumentos }
                            total={ recordsCountDocumentos } 
                            onChange={ ( page, pageSize ) => { setPageDocumentos( page ); setRowsPerPageDocumentos( pageSize ); } } 
                        />
                    </Col>
                </Row>

                <Row gutter={[24, 0]} style={{ padding: '1em' }} >
                    <Col span={24} style={{ textAlign: 'center' }}>
                        <Button
                            icon={ <DownloadOutlined /> }
                            href={"/Api/Expediente/DescargarDocumentosExpediente/" + idExpediente }
                            target="_blank"
                        >
                            Descargar documentos
                        </Button>
                    </Col>
                </Row>

            </Modal>

            <Modal
                title="Documento"
                open={isModalDocumentoVisible}
                maskClosable={false}
                centered
                footer={null}
                onCancel={() => { setIsModalDocumentoVisible(false); }}
                width={800}
            >
                <iframe
                    title='iframe documento'
                    src={documento}
                    style={{ width: '100%', height: '500px' }}
                />
            </Modal>
            
        </>
    );

}
