import { useEffect, useState } from "react";

import { Row, Col, Card, Table, Button, Modal, Typography, Pagination, Input, Select } from "antd";

import { ClearOutlined, FolderAddOutlined, SearchOutlined } from "@ant-design/icons";

import { ObtenerMateria } from "../actions/materiaAction";
import { ObtenerDistritoJudicial } from "../actions/distritoJudicialActions";
import { ObtenerJuzgados } from "../actions/juzgadoActions";

import { ConsultaExpediente, PasarAExpediente, PasarANotificacion } from "../actions/consultaExpedienteActions";
import { columnsConsultarExpedientes } from "../helpers/columnsConsultarExpedientes";

const { Title } = Typography;

export const ConsultarExpedientes = () => {

    const [page, setPage] = useState( 1 );
    const [rowsPerPage, setRowsPerPage] = useState( 10 );
    const [recordsCount, setRecordsCount] = useState( 0 );

    const [data, setData] = useState( [] );

    const [loading, setLoading] = useState( false );
    const [loadingRegistrarExpediente, setLoadingRegistrarExpediente] = useState( false );
    const [loadingRegistrarNotificacion, setLoadingRegistrarNotificacion] = useState( false );

    const [referencia, setReferencia] = useState( '' );
    const [openRegistrarExpediente, setOpenRegistrarExpediente] = useState( false );
    const [openRegistrarNotificacion, setOpenRegistrarNotificacion] = useState( false );

    const [juzgado, setJuzgado] = useState( 0 );
    const [juzgadosArray, setJuzgadosArray] = useState( [] );

    const [materia, setMateria] = useState( 0 );
    const [materiaArray, setMateriaArray] = useState( [] );

    const [distritoJudicial, setDistritoJudicial] = useState( 0 )
    const [distritoJudicialArray, setDistritoJudicialArray] = useState( [] );

    const [numExpediente, setNumExpediente] = useState( '' );
    const [nombre, setNombre] = useState( '' );

    const [filtroAplicado, setFiltroAplicado] = useState( false );

    const [reload, setReload] = useState( false );

    const agregarExpediente = async ( ) => {

        setLoadingRegistrarExpediente( true );

        await PasarAExpediente({ IdExpedienteGlobal: referencia }).then(response => {
            
            if ( response ) {

                if ( response.status === 200 ) {

                    const { success, message } = response.data;

                    if( success ){

                        setOpenRegistrarExpediente( false );

                        setReload( true );

                        Modal.success({
                            title: 'Información registrada',
                            content: message,
                            centered: true,
                        });

                        setLoadingRegistrarExpediente( false );

                    }
                    else{
                        Modal.error({
                            title: 'Error',
                            content: message,
                            centered: true,
                        });

                        setLoadingRegistrarExpediente( false );
                    }

                }

            }

        });

    }

    const agregarNotificacion = async ( ) => {

        setLoadingRegistrarNotificacion( true );

        await PasarANotificacion({ IdExpedienteGlobal: referencia } ).then(response => {

            if ( response ) {

                if ( response.status === 200 ) {
                
                    const { success, message } = response.data;

                    if( success ){

                        setTimeout(() => {

                            setOpenRegistrarNotificacion( false );

                            setReload( true );

                            Modal.success({
                                title: 'Información registrada',
                                content: message,
                                centered: true,
                            });

                            setLoadingRegistrarNotificacion( false );
                            
                        }, 1000);

                    }
                    else{

                        setTimeout(() => {

                            Modal.error({
                                title: 'Error',
                                content: message,
                                centered: true,
                            });

                            setLoadingRegistrarNotificacion( false );
                            
                        }, 1000);

                    }

                }

            }

        });
       
    }

    const handleBuscar = () => {

        setFiltroAplicado( true )
        setPage( 1 );

    }

    const handleLimpiarFiltro = () => {

        setFiltroAplicado( false );
        
        setNumExpediente( '' );
        setJuzgado( 0 );
        setMateria( 0 );
        setDistritoJudicial( 0 );
        setNombre( '' );

        setData( [] );
        
        setPage( 1 );
        setRecordsCount( 0 );
    }

    useEffect(() => {

        async function Obtener() {

            await ObtenerDistritoJudicial().then( response => {
   
                if ( response ) {

                    if ( response.status === 200 ) {

                        if ( response.data ){
                            setDistritoJudicialArray( response.data );
                        }

                    }

                }

            });

        }

        Obtener();

    }, [])
    
    useEffect(() => {

        async function Obtener() {

            await ObtenerMateria().then( response => {

                if ( response ) {

                    if ( response.status === 200) {

                        if ( response.data ) {
                            setMateriaArray( response.data );
                        }

                    }

                }

            });

        }

        Obtener();

    }, [])
    
    useEffect(() => {

        async function Obtener() {

            if( materia !== 0 ){

                const params ={
                    idMateria: materia,
                    idDistritoJudicial: distritoJudicial
                }

                await ObtenerJuzgados( params ).then(response => {
    
                    if ( response ) {
    
                        if ( response.status === 200 ) {
    
                            if ( response.data ) {
                                setJuzgadosArray( response.data );
                            }
    
                        }
    
                    }
    
                });
            }

        }

        if( materia !== 0 && distritoJudicial !== 0 ){
            Obtener();
        }

    }, [ materia, distritoJudicial ])

    useEffect(() => {

        async function Obtener() {

            setLoading( true );
            setData( [] );

            await ConsultaExpediente({ page: page - 1, rowsPerPage, Persona: nombre, numeroExpediente: numExpediente, idMateria: materia, idDistritoJudicial: distritoJudicial, idTipoJuzgado: juzgado }).then(response => {

                if ( response ) {

                    if ( response.status === 200 ) {

                        if ( response.data ) {

                            setTimeout(() => {

                                setRecordsCount(response.data.recordsCount ?? 0);

                                var data = response?.data?.records.map( ( elem ) => {

                                    return {
                                        key: elem.idExpediente,
                                        folio: <Title level={5}> {elem.folio}</Title>,
                                        expediente: <Title level={5}>{elem.numeroExpediente !== "" ? (elem.esExhorto ? 'EXH' : 'EXP') : ""} {elem.numeroExpediente}</Title>,
                                        juzgado: <Title level={5}>Juzgado {elem.tipoJuzgado} Primera Instancia en Materia {elem.materia} del distrito Judicial de {elem.distritoJudicial}</Title>,
                                        presentante: <Title level={5}>{elem.presentante}</Title>,
                                        actor: <Title level={5}>{elem.actor}</Title>,
                                        demandado: <Title level={5}>{elem.demandado}</Title>,
                                        actions: (
                                            <div style={{ textAlign: 'center' }}>

                                                <Button 
                                                    onClick={ () => { setReferencia( elem.idExpediente ); setOpenRegistrarExpediente( true ); } } 
                                                    style={{ fontSize: '25px', marginLeft: '1px' }} 
                                                    type="link" 
                                                    disabled={ elem.esExhorto || elem.virtual }
                                                >   
                                                    <FolderAddOutlined />
                                                </Button>

                                                {/*<Button 
                                                    onClick={ () => { setReferencia( elem.idExpediente ); setOpenRegistrarNotificacion( true ); } } 
                                                    style={{ fontSize: '25px', marginLeft: '1px' }} 
                                                    type="link" 
                                                    disabled={ elem.esExhorto || elem.notificacion }
                                                >
                                                    <SendOutlined />
                                                </Button>*/}

                                            </div>
                                        )
                                    }
                                })

                                setLoading( false );
                                setData( data );
                                setReload( false );

                            }, 1000);
                        }

                    }

                }

            });
        }

        if( filtroAplicado ){
            Obtener();
        }

    }, [ page, rowsPerPage, filtroAplicado, distritoJudicial, materia, numExpediente, nombre, juzgado ])

    useEffect(() => {

        async function Obtener() {

            setLoading( true );
            setData( [] );

            await ConsultaExpediente({ page: page - 1, rowsPerPage, Nombre: nombre, numeroExpediente: numExpediente, idMateria: materia, idDistritoJudicial: distritoJudicial, idTipoJuzgado: juzgado }).then(response => {

                if ( response ) {

                    if ( response.status === 200 ) {

                        if ( response.data ) {

                            setTimeout(() => {

                                setRecordsCount(response.data.recordsCount ?? 0);

                                var data = response?.data?.records.map( ( elem ) => {

                                    return {
                                        key: elem.idExpediente,
                                        folio: <Title level={5}> {elem.folio}</Title>,
                                        expediente: <Title level={5}>{elem.numeroExpediente !== "" ? (elem.esExhorto ? 'EXH' : 'EXP') : ""} {elem.numeroExpediente}</Title>,
                                        juzgado: <Title level={5}>Juzgado {elem.tipoJuzgado} Primera Instancia en Materia {elem.materia} del distrito Judicial de {elem.distritoJudicial}</Title>,
                                        presentante: <Title level={5}>{elem.presentante}</Title>,
                                        actor: <Title level={5}>{elem.actor}</Title>,
                                        demandado: <Title level={5}>{elem.demandado}</Title>,
                                        actions: (
                                            <div style={{ textAlign: 'center' }}>

                                                <Button 
                                                    onClick={ () => { setReferencia( elem.idExpediente ); setOpenRegistrarExpediente( true ); } } 
                                                    style={{ fontSize: '25px', marginLeft: '1px' }} 
                                                    type="link" 
                                                    disabled={ elem.esExhorto || elem.virtual }
                                                >   
                                                    <FolderAddOutlined />
                                                </Button>

                                                {/*<Button 
                                                    onClick={ () => { setReferencia( elem.idExpediente ); setOpenRegistrarNotificacion( true ); } } 
                                                    style={{ fontSize: '25px', marginLeft: '1px' }} 
                                                    type="link" 
                                                    disabled={ elem.esExhorto || elem.notificacion }
                                                >
                                                    <SendOutlined />
                                                </Button>*/}

                                            </div>
                                        )
                                    }
                                })

                                setLoading( false );
                                setData( data );
                                setReload( false );

                            }, 1000);
                        }

                    }

                }

            });
        }

        if( reload ){
            Obtener();
        }

    }, [ page, rowsPerPage, reload, distritoJudicial, materia, numExpediente, nombre, juzgado ])

    return (
        <>
            <div className="tabled">

                <Row gutter={[24, 0]}>

                    <Col xs="24" xl={24} >

                        <Card
                            bordered={false}
                            className="criclebox tablespace mb-24"
                            style={{ width: '100%', padding: '1em' }}
                        >

                            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} justify='end'>

                                <Col className="gutter-row" span={6}>
                                    <Input placeholder="1/2022" value={numExpediente} onChange={(e) => { setNumExpediente(e.target.value)}} disabled={filtroAplicado} />
                                </Col>

                                <Col className="gutter-row" span={6}>
                                    <Select size="large" style={{ width: '100%' }} value={distritoJudicial} onChange={(e) => { setDistritoJudicial(e)}} disabled={filtroAplicado}>
                                        <Select.Option value={0}>Seleccione una opción</Select.Option>
                                        {
                                            distritoJudicialArray.map((element) => (
                                                <Select.Option key={element.idDistritoJudicial} value={element.idDistritoJudicial}>{element.descripcion}</Select.Option>
                                                
                                            ))
                                        }
                                    </Select>
                                </Col>

                                <Col className="gutter-row" span={6}>
                                    <Select size="large" style={{ width: '100%' }} value={materia} onChange={(e) => {setMateria(e) }} disabled={ distritoJudicial === 0 || filtroAplicado }>
                                        <Select.Option value={0}>Seleccione una opción</Select.Option>
                                        {
                                            materiaArray.map((element) => (
                                                <Select.Option key={element.idMateria} value={element.idMateria}>{element.descripcion}</Select.Option>
                                                
                                            ))
                                        }
                                    </Select>
                                </Col>

                                <Col className="gutter-row" span={6}>
                                    <Select size="large" style={{ width: '100%' }} value={juzgado} onChange={(e) => {setJuzgado(e) }} disabled={ materia === 0 || filtroAplicado } >
                                        <Select.Option value={0}>Seleccione una opción</Select.Option>
                                    {
                                            juzgadosArray.map((element) => (
                                                <Select.Option key={element.idTipoJuzgado} value={element.idTipoJuzgado }>{element.tipoJuzgado.descripcion}</Select.Option>
                                                
                                            ))
                                        }
                                    </Select>
                                </Col>

                                <Col className="gutter-row" span={12}>
                                    <Input style={{ marginTop: '1em'  }} placeholder="Nombre de la persona" value={nombre} onChange={(e) => { setNombre(e.target.value)}} disabled={ filtroAplicado } />
                                </Col>

                                <Col className="gutter-row" span={6}  >
                                    <Button 
                                        style={{ fontSize: '14px', width: '100%', marginTop: '1em' }} 
                                        icon={ filtroAplicado ? <ClearOutlined /> : <SearchOutlined /> } 
                                        onClick={ () => filtroAplicado ? handleLimpiarFiltro() : handleBuscar() }
                                        disabled={ numExpediente === '' && distritoJudicial === 0 && nombre === '' } 
                                        loading={ loading }
                                    >
                                        { filtroAplicado ? 'Limpiar' : 'Buscar' }
                                    </Button>
                                </Col>
                                
                            </Row>

                        </Card>

                        <Card
                            bordered={false}
                            className="criclebox tablespace mb-24"
                            style={{ width: '100%' }}
                        >
                            <div className="table-responsive" style={{ marginTop: '20px' }}>

                                <Table
                                    dataSource={ data }
                                    pagination={ false }
                                    className="ant-border-space"
                                    columns={ columnsConsultarExpedientes }
                                    loading={ loading }
                                />

                            </div>

                            <div style={{ float: 'left', padding: '15px' }} >
                                <Title level={5}>Total de expedientes: {recordsCount}</Title>
                            </div>

                            <div style={{ float: 'right', padding: '15px' }} >
                                <Pagination 
                                    current={ page }
                                    pageSize={ rowsPerPage }
                                    total={ recordsCount } 
                                    onChange={ ( page, pageSize ) => { setPage(page); setRowsPerPage(pageSize); } } 
                                />
                            </div>

                        </Card>

                    </Col>

                </Row>

                <Modal
                    open={ openRegistrarExpediente }
                    title="Confirmación"
                    onOk={ () => {} }
                    onCancel={ () => setOpenRegistrarExpediente( false ) }
                    centered
                    footer={[
                        <Button key="cancelar" type="link" onClick={ () => setOpenRegistrarExpediente( false ) }>
                            Cancelar
                        </Button>,
                        <Button key="registrarVirtual" type="ghost"  onClick={ agregarExpediente } loading={ loadingRegistrarExpediente }>
                            Registrar expediente
                        </Button>,
                    ]}
                >
                    ¿Desea agregar el expediente seleccionado al expediente virtual?
                </Modal>

                <Modal
                    open={ openRegistrarNotificacion }
                    title="Confirmación"
                    onOk={ () => {} }
                    onCancel={ () => setOpenRegistrarNotificacion( false ) }
                    centered
                    footer={[
                        <Button key="cancelar" type="link" onClick={ () => setOpenRegistrarNotificacion( false ) }>
                            Cancelar
                        </Button>,
                        <Button key="registrarVirtual" type="ghost"  onClick={ agregarNotificacion } loading={ loadingRegistrarNotificacion } >
                            Registrar notificacion
                        </Button>,
                    ]}
                >
                    ¿Desea agregar el expediente para recibir notificación de nuevos acuerdos?
                </Modal>
             
            </div>

        </>
    );

}
