import React from 'react';

import { Navigate, Route, Routes } from 'react-router-dom';

import { Dashboard } from "../pages/Dashboard";
import { Usuarios } from "../pages/Usuarios";
import { ConsultarExpedientes } from "../pages/ConsultarExpedientes";
import { ExpedienteVirtual } from "../pages/ExpedienteVirtual";
import { Notificaciones } from "../pages/Notificaciones";
import { Apikeys } from '../pages/Apikeys';

export const DataWarehouseRoutes = () => {

    return (
        <Routes> 

            <Route exact path="/dashboard/" element={ <Dashboard /> } />
            <Route exact path="/Usuarios/" element={ <Usuarios /> } />
            <Route exact path="/ConsultarExpedientes/" element={ <ConsultarExpedientes /> } />
            <Route exact path="/ExpedienteVirtual/" element={ <ExpedienteVirtual /> } />
            <Route exact path="/Notificaciones/" element={ <Notificaciones /> } />     
            <Route exact path="/Apikeys/" element={ <Apikeys /> } />                            

            <Route path="*" element={ <Navigate to="/dashboard/" /> } />                         

        </Routes>
    )

}